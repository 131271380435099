<template>
  <VChip
    :data-test="dataTest"
    :data-test-label="dataTestLabel"
    :data-test-value="dataTestValue"

    v-bind="{...$attrs, ...$props}"
    :ripple="ripple"
    :outlined="outlined"
    v-on="$listeners"
  >
    <span class="v-chip__content"><slot /></span>
    <span
      v-if="counter !== undefined"
      class="v-chip--counter"
    >
      {{ counter }}
    </span>
  </VChip>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'TTChip',
  model: {
    event: 'change',
  },
  props: {
    dataTest: {
      type: String,
      default: 'tt-chip',
    },
    dataTestLabel: {
      type: String,
      default: '',
    },
    dataTestValue: {
      type: String,
      default: '',
    },

    counter: {
      type: [Number, String],
      default: undefined,
    },
    ripple: { type: Boolean, default: false },
    outlined: { type: Boolean, default: true },
  },
});
</script>

<style lang="scss" src="./TTChip.scss"></style>
