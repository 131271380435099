import i18n from '@/plugins/vue-i18n';

export const ALL_FACTORS_NAME_ICON = {
  awareness_level: '$bookReader',
  work_and_life_balance: '$heart',
  salary: '$piggyBank',
  stability: '$wallet',
  work_content: '$briefcase',
  stress_level: '$leaf',
  working_conditions: '$hospital',
  social_benefits: '$userShield',
  resources_and_tools: '$desktop',
  workflow: '$users',
  me_and_client: '$handshake',
  me_and_team: '$usersLight',
  me_and_company: '$building',
  me_and_work: '$briefcaseLight',
  me_and_head: '$userTieLight',
  top_management_openness: '$userTie',
  perception_of_strategy: '$analytics',
  career_opportunities: '$signal',
  personal_effectiveness: '$award',
  inspiration: '$fire',
  professional_development: '$rocketLaunch',
  feedback: '$commentAltCheck',
  help_in_solving_complex_problems: '$magic',
  independence: '$route',
  team_interaction: '$usersClass',
  team_support: '$userFriends',
  improving_efficiency: '$thumbsUp',
  customer_insight: '$handshake',
  interaction_with_subcontractors: '$projectDiagram',
  working_for_customer_satisfaction: '$starLight',
};

export const FACTOR_NAME = {
  SATISFACTION: 'satisfaction',
  ENGAGEMENT: 'engagement',
  ENPS_DIVISION: 'enps_division',
  ENPS_COMPANY: 'enps_company',
};

export const BASE_FACTOR_NAME = {
  AWARENESS: 'awareness_level',
  WORK_LIFE: 'work_and_life_balance',
  SALARY: 'salary',
  STABILITY: 'stability',
  WORK_CONTENT: 'work_content',
  STRESS: 'stress_level',
  WORKING_CONDITIONS: 'working_conditions',
  SOCIAL_BENEFITS: 'social_benefits',
  RESOURCES_TOOLS: 'resources_and_tools',
  WORKFLOW: 'workflow',
};

export const BASE_FACTOR_NAME_ICON = {
  awareness_level: '$bookReader',
  work_and_life_balance: '$heart',
  salary: '$piggyBank',
  stability: '$wallet',
  work_content: '$briefcase',
  stress_level: '$leaf',
  working_conditions: '$hospital',
  social_benefits: '$userShield',
  resources_and_tools: '$desktop',
  workflow: '$users',
};

export const ENHANCING_FACTOR_NAME = {
  ME_AND_HEAD: 'me_and_head',
  ME_AND_TEAM: 'me_and_team',
  ME_AND_WORK: 'me_and_work',
  ME_AND_COMPANY: 'me_and_company',
  ME_AND_CLIENT: 'me_and_client',
};

export const ENHANCING_FACTOR_NAME_DESCR = {
  me_and_head: i18n.t('enhancingFactorsNames.meAndHead'),
  me_and_team: i18n.t('enhancingFactorsNames.meAndTeam'),
  me_and_work: i18n.t('enhancingFactorsNames.meAndWork'),
  me_and_company: i18n.t('enhancingFactorsNames.meAndCompany'),
  me_and_client: i18n.t('enhancingFactorsNames.meAndClient'),
};

export const ENHANCING_FACTOR_NAME_ICON = {
  me_and_client: '$handshake',
  me_and_team: '$usersLight',
  me_and_company: '$building',
  me_and_work: '$briefcaseLight',
  me_and_head: '$userTieLight',
};

export const ENHANCING_SUBFACTOR_NAME = {
  TOP_MANAGEMENT_OPENNESS: 'top_management_openness',
  PERCEPTION_OF_STRATEGY: 'perception_of_strategy',
  CAREER_OPPORTUNITIES: 'career_opportunities',
  PERSONAL_EFFECTIVENESS: 'personal_effectiveness',
  INSPIRATION: 'inspiration',
  PROFESSIONAL_DEVELOPMENT: 'professional_development',
  FEEDBACK: 'feedback',
  HELP_IN_SOLVING_COMPLEX_PROBLEMS: 'help_in_solving_complex_problems',
  INDEPENDENCE: 'independence',
  TEAM_INTERACTION: 'team_interaction',
  TEAM_SUPPORT: 'team_support',
  IMPROVING_EFFICIENCY: 'improving_efficiency',
  CUSTOMER_INSIGHT: 'customer_insight',
  INTERACTION_WITH_SUBCONTRACTORS: 'interaction_with_subcontractors',
  WORKING_FOR_CUSTOMER_SATISFACTION: 'working_for_customer_satisfaction',
};

export const ENHANCING_SUBFACTOR_GROUP = {
  top_management_openness: 'me_and_company',
  perception_of_strategy: 'me_and_company',
  career_opportunities: 'me_and_company',
  personal_effectiveness: 'me_and_work',
  inspiration: 'me_and_work',
  professional_development: 'me_and_work',
  feedback: 'me_and_head',
  help_in_solving_complex_problems: 'me_and_head',
  independence: 'me_and_head',
  team_interaction: 'me_and_team',
  team_support: 'me_and_team',
  improving_efficiency: 'me_and_team',
  customer_insight: 'me_and_client',
  interaction_with_subcontractors: 'me_and_client',
  working_for_customer_satisfaction: 'me_and_client',
};

export const ENHANCING_SUBFACTOR_NAME_ICON = {
  top_management_openness: '$userTie',
  perception_of_strategy: '$analytics',
  career_opportunities: '$signal',
  personal_effectiveness: '$award',
  inspiration: '$fire',
  professional_development: '$rocketLaunch',
  feedback: '$commentAltCheck',
  help_in_solving_complex_problems: '$magic',
  independence: '$route',
  team_interaction: '$usersClass',
  team_support: '$userFriends',
  improving_efficiency: '$thumbsUp',
  customer_insight: '$handshake',
  interaction_with_subcontractors: '$projectDiagram',
  working_for_customer_satisfaction: '$starLight',
};

export const SURVEY_STATUS = {
  ACTIVE: 'active',
  FINISHED: 'finished',
  DRAFT: 'draft',
  PUBLISHED: 'published',
  CURRENT: 'current',
};

export const USER_SURVEY_SLUG_SETTINGS = {
  PERSONAL: 'personal',
  AUTHORIZATION: 'authorization',
  ANONYMOUS: 'anonymous',
  SHARED: 'shared',
  TSXASS: 'tsxass',
};

export const mapRouteToAnalyticsKind = {
  engagement: 'R_APP_SURVEY_ID_ANALYTICS_DASHBOARD',
  user: 'R_APP_CONSTRUCTION_RESULTS_SURVEY_ID',
  occupational_burnout: 'R_APP_SURVEY_ID_BURNOUT',
  csi: 'R_APP_SURVEY_ID_CSI',
  express_svs: 'R_APP_SURVEY_ID_EXPRESS',
  svs_engagement: 'R_APP_SURVEY_ID_FLEXIBLE_ANALYTICS',
};

export const VIEWING_RIGHTS = {
  DISPLAY_ALL: 'display_all',
  DISPLAY_HEADS: 'display_heads',
  DISPLAY_BRANCH: 'display_branch',
};

export const PRACTICE_SOURCE = {
  TT: 'tt',
  USER: 'user',
};

export const BRANCHING_STATUS = {
  // страница не имеет входящих связей - страница не является следующей ни у одной страницы опроса
  USELESS: 'useless',
  // Завершающая страница - у страницы есть входящая связь с другой страницы
  // (не важно по итогам ветвления или безусловная),
  // но нет исходящих связей по итогам ветвления или безусловных
  FINISH: 'finish',
  // Условно завершающая страница - страница является завершающей в поливариативном ветвлении
  POLY_FINISH: 'poly_finish',
  // Есть входящая связь и исходящая
  STANDARD: 'standard',
};

export const ROLES = {
  JUST_USER: 'just_user',
  MANAGER: 'manager',
  ADMIN: 'admin',
  SUDO_ADMIN: 'sudo_admin',
};

export const ABILITIES = {
  CAN_SEE_SETTINGS_LEFT_MENU: 'can_see_settings_left_menu',
  CAN_SEE_HR_PRACTICES_LEFT_MENU: 'can_see_hr_practices_left_menu',
  CAN_SEE_SURVEYS_LEFT_MENU: 'can_see_surveys_left_menu',
  CAN_SEE_CONSTRUCTOR_LEFT_MENU: 'can_see_constructor_left_menu',
  CAN_EDIT_ROLES: 'can_edit_roles',
  CAN_EDIT_SURVEY_VIEWING_RIGHTS: 'can_edit_survey_viewing_rights',
  CAN_SEE_ALL_SURVEY_DRAFTS: 'can_see_all_survey_drafts',
  SURVEY_CONSTRUCTOR_BANNER: 'survey_constructor_banner',
  CAN_CREATE_SURVEY: 'can_create_survey',
  CAN_SAVE_SURVEY_TEMPLATE: 'can_save_survey_template',
  CAN_CRUD_ALL_SURVEY_DRAFTS: 'can_crud_all_survey_drafts',
  CAN_CREATE_PRACTICE: 'can_create_practice',
  CAN_DUPLICATE_ANY_SURVEY_FROM_QUEUE: 'can_duplicate_any_survey_from_queue',
  CAN_REMOVE_ALL_SURVEYS_FROM_QUEUE: 'can_remove_all_surveys_from_queue',
  CAN_COPY_LINK_FOR_ANY_QUEUE_SURVEY: 'can_copy_link_for_any_queue_survey',
  CAN_STOP_OR_RESUME_ANY_SURVEY: 'can_stop_or_resume_any_survey',
  CAN_CHANGE_SURVEY_VIEWING_RIGHTS: 'can_change_survey_viewing_rights',
  CAN_DUPLICATE_ANY_ACTIVE_SURVEY: 'can_duplicate_any_active_survey',
  CAN_CRUD_ONLY_OWN_SURVEY_DRAFTS: 'can_crud_only_own_survey_drafts',
  CAN_REMOVE_ONLY_OWN_SURVEYS_FROM_QUEUE: 'can_remove_only_own_surveys_from_queue',
  CAN_DUPLICATE_ONLY_OWN_SURVEY_FROM_QUEUE: 'can_duplicate_only_own_survey_from_queue',
  CAN_COPY_LINK_ONLY_BRANCH_QUEUE_SURVEY: 'can_copy_link_only_branch_queue_survey',
  CAN_SEE_ONLY_OWN_SURVEY_DRAFTS: 'can_see_only_own_survey_drafts',
  CAN_STOP_OR_RESUME_ONLY_OWN_SURVEY: 'can_stop_or_resume_only_own_survey',
  CAN_DUPLICATE_ONLY_OWN_ACTIVE_SURVEY: 'can_duplicate_only_own_active_survey',
  CAN_CREATE_SURVEY_FROM_CSI_TEMPLATE: 'can_create_survey_from_csi_template',
  CAN_ADD_FACTORS_TO_QUESTION: 'can_add_factors_to_question',
  REQUEST_SURVEY_BANNER: 'request_survey_banner',
  CAN_SEE_USER_SURVEYS_LEFT_MENU: 'can_see_user_surveys_left_menu',
  CAN_DUPLICATE_ANY_FINISHED_SURVEY: 'can_duplicate_any_finished_survey',
  CAN_DUPLICATE_ONLY_OWN_FINISHED_SURVEY: 'can_duplicate_only_own_finished_survey',
  CAN_EDIT_ANY_STATUS_SURVEY: 'can_edit_any_status_survey',
  CAN_CREATE_FACTORS: 'can_create_factors',
  CAN_SEE_APPOINTED_SURVEYS: 'can_see_appointed_surveys',
  CAN_EXPORT_RAW_DATA: 'can_export_raw_data',
  CAN_SEE_COMPLEX_REPORTS: 'can_view_complex_reports',
  CAN_CREATE_COMPLEX_REPORTS: 'can_create_complex_reports',
  CAN_START_ANY_SURVEY: 'can_start_any_survey',
  CAN_START_ONLY_OWN_SURVEY: 'can_start_only_own_survey',
  CAN_CHANGE_SURVEY_AUDITORY_COUNT_SOURCE: 'can_change_survey_auditory_count_source',
  CAN_MANAGE_DETECT_FAKES_PROPERTY: 'can_manage_detect_fakes_property',
  CAN_SEE_FAKE_USER_SURVEYS: 'can_see_fake_user_surveys',
  CAN_IMPORT_EXTERNAL_SURVEYS: 'can_import_external_surveys',
  CAN_FORCE_SYNC_COMPANY: 'can_force_sync_company',
  CAN_EDIT_ANALYTICS_VIZUALIZATION: 'can_edit_analytics_vizualization',
  CAN_CRUD_SURVEY_DRAFTS: 'can_crud_survey_drafts',
  CAN_CRUD_360: 'can_crud_360',
  CAN_CRUD_SURVEY_TT_TEMPLATES: 'can_crud_survey_tt_templates',
  CAN_DELETE_PDF: 'can_delete_pdf',
  CAN_SKIP_TIMER: 'can_skip_timer',
  CAN_VIEW_EMPLOYEES_ANALYTICS_360: 'can_view_employees_analytics_360',
  CAN_CHANGE_ACCESS_TO_ANALYTICS_360: 'can_change_access_to_analytics_360',
  CAN_SEND_REMINDERS_360: 'can_send_reminders_360',
  CAN_START_SURVEY_360: 'can_start_survey_360',
  CAN_STOP_SURVEY_360: 'can_stop_survey_360',
  CAN_RESUME_SURVEY_360: 'can_resume_survey_360',
  CAN_REMOVE_SURVEY_FROM_QUEUE_360: 'can_remove_survey_from_queue_360',
  CAN_CREATE_SURVEY_360: 'can_create_survey_360',
  CAN_COPY_SURVEY_LINK_360: 'can_copy_survey_link_360',
  CAN_CHANGE_SURVEY_VIEWING_PERMISSIONS_360: 'can_change_survey_viewing_permissions_360',
  CAN_SEE_DRAFT_SURVEYS_360: 'can_see_draft_surveys_360',
  CAN_EDIT_ANY_SURVEY_360: 'can_edit_any_survey_360',
  CAN_EDIT_DRAFT_SURVEY_360: 'can_edit_draft_survey_360',
  CAN_EDIT_SURVEY_IN_QUEUE_360: 'can_edit_survey_in_queue_360',
  CAN_SEE_SURVEY_ANALYTICS_360: 'can_see_survey_analytics_360',
  CAN_SEE_SURVEY_STATISTICS_360: 'can_see_survey_statistics_360',
  CAN_DELETE_SURVEY_360: 'can_delete_survey_360',
  CAN_EDIT_ACTIVE_SURVEYS: 'can_edit_active_surveys',
  CAN_SEE_COMPETENCIES_LEFT_MENU: 'can_see_competencies_left_menu',
  CAN_RUN_YANDEX_GPT: 'manager_gpt',
  CAN_CRUD_CARDS_IN_ALL_TEAMS: 'can_crud_cards_in_all_teams',
};

export const FACTORS_COLORS = [
  {
    name: 'awareness_level',
    color: '#B2EBF2',
  },
  {
    name: 'salary',
    color: '#64DD17',
  },
  {
    name: 'stress_level',
    color: '#80CBC4',
  },
  {
    name: 'stability',
    color: '#C51162',
  },
  {
    name: 'career_opportunities',
    color: '#1E88E5',
  },
  {
    name: 'customer_insight',
    color: '#FFC700',
  },
  {
    name: 'work_content',
    color: '#FF7043',
  },
  {
    name: 'work_and_life_balance',
    color: '#33FF00',
  },
  {
    name: 'resources_and_tools',
    color: '#90A4AE',
  },
  {
    name: 'working_conditions',
    color: '#FF8A80',
  },
  {
    name: 'inspiration',
    color: '#90CAF9',
  },
  {
    name: 'social_benefits',
    color: '#64DD17',
  },
  {
    name: 'workflow',
    color: '#B388FF',
  },
  {
    name: 'top_management_openness',
    color: '#FF26E1',
  },
  {
    name: 'perception_of_strategy',
    color: '#C0CA33',
  },
  {
    name: 'personal_effectiveness',
    color: '#9FA8DA',
  },
  {
    name: 'professional_development',
    color: '#AB47BC',
  },
  {
    name: 'feedback',
    color: '#FFC700',
  },
  {
    name: 'help_in_solving_complex_problems',
    color: '#A1887F',
  },
  {
    name: 'independence',
    color: '#4DD0E1',
  },
  {
    name: 'team_interaction',
    color: '#601EF3',
  },
  {
    name: 'team_support',
    color: '#90A4AE',
  },
  {
    name: 'improving_efficiency',
    color: '#AB47BC',
  },
  {
    name: 'interaction_with_subcontractors',
    color: '#FF26E1',
  },
  {
    name: 'working_for_customer_satisfaction',
    color: '#FFC700',
  },
];

export const HEATMAP_COLORS = ['#FF2D3D', '#FF522D', '#FF7020', '#FF8914', '#FFA607', '#E7B407', '#B4B015', '#80AC23', '#49A832', '#03A344'];

export const COLOR_RANGE_5 = ['#E5453F', '#F8832B', '#FFBF00', '#929801', '#327E31'];

export const COLOR_RANGE_6 = ['#E5453F', '#F06A34', '#FF8D00', '#FFBF00', '#929801', '#327E31'];

export const COLOR_RANGE_7 = ['#E5453F', '#F06A34', '#FF8D00', '#FFBF00', '#B2B400', '#929801', '#327E31'];

export const COLOR_RANGE_8 = ['#E5453F', '#F06A34', '#FF8D00', '#FFA900', '#FFBF00', '#B2B400', '#929801', '#327E31'];

export const COLOR_RANGE_9 = ['#E5453F', '#F06A34', '#FF8D00', '#FFA900', '#FFBF00', '#B2B400', '#929801', '#6A8D24', '#327E31'];

export const DS_COLORS_1 = ['#0D47A1'];

export const DS_COLORS_2 = ['#EF5350', '#2E7D32'];

export const DS_COLORS_3 = ['#EF5350', '#FFC700', '#66BB6A'];

export const DS_COLORS = ['#EF5350', '#FFC700', '#66BB6A', '#2E7D32'];

export const BENCH_COLORS = ['#0D47A1', '#B71C1C', '#FF6E37', '#6A1B9A', '#B0BEC5'];

export const EMAIL_TEMPLATES_NAMES = {
  START: 'start',
  REMINDER: 'reminder',
  UNSUBSCRIBE_REMINDER: 'unsubscribe_reminder',
};

export const FACTORS_ICON_PACK = [
  '$cog',
  '$search',
  '$questionCircleLight',
  '$signOut',
  '$fileAltLight',
  '$chevronCircleRightLight',
  '$playCircleLight',
  '$bookLight',
  '$handshakeLight',
  '$qrcodeLight',
  '$handPointDownLight',
  '$boxLight',
  '$chartBarLight',
  '$commentLight',
  '$envelopeLight',
  '$file',
  '$linkLight',
  '$expandLight',
  '$penLight',
  '$edit',
  '$bell',
  '$tasksLight',
  '$glassesLight',
  '$clock',
  '$banLight',
  '$loading',
  '$eyeLight',
  '$eyeSlashLight',
  '$shareAltLight',
  '$borderAllLight',
  '$calendarAltLight',
  '$userPlusLight',
  '$userFriends',
  '$envelopeOpenLight',
  '$chartPieAltLight',
  '$smileLight',
  '$thumbsUp',
  '$clipboardCheck',
  '$exclamationCircleLight',
  '$swatchBookLight',
  '$fireLight',
  '$handPointerLight',
  '$commentAltPlusLight',
  '$fileCheckLight',
  '$fileEditLight',
  '$layerGroupLight',
  '$commentAltCheckLight',
  '$presentationLight',
  '$bullseyePointerLight',
  '$userChartLight',
  '$usersClassLight',
  '$columnsLight',
  '$bullseyeLight',
  '$projectDiagram',
  '$analytics',
  '$awardLight',
  '$usersLight',
  '$grinStartLight',
  '$folderOpenLight',
  '$userHardHatLight',
  '$flagLight',
  '$bullhornLight',
  '$commentsDotsLight',
  '$fileSpreadsheetLight',
  '$copy',
  '$lockAltLight',
  '$starLight',
  '$commentAltLinesLight',
  '$tachometerLight',
  '$folderTreeLight',
  '$usersCrownLight',
  '$chartLineLight',
  '$lockOpenAltLight',
  '$userCheckLight',
  '$filePlusLight',
  '$graduationCapLight',
  '$userTieLight',
  '$pollPeopleLight',
  '$chartPie',
  '$stopWatchLight',
  '$balanceScaleRightLight',
  '$clipboardListCheckLight',
  '$fileWordLight',
  '$filterLight',
  '$cameraLight',
  '$mapMarkerAltLight',
  '$calendarEditLight',
  '$clipboardUserLight',
  '$phoneLight',
  '$fileExcel',
  '$filePowerpointLight',
  '$fileImageLight',
  '$fileVideoLight',
  '$filePdfLight',
  '$megaphoneLight',
  '$calendarCheckLight',
  '$save',
  '$info',
  '$puzzleLight',
  '$userLight',
  '$fileTimesLight',
  '$fileCertificateLight',
  '$keyboardLight',
  '$codeLight',
  '$caretCircleDownLight',
  '$arrowSquareUpLight',
  '$bullseyeArrowLight',
  '$cameraAltLight',
  '$userEditLight',
  '$videoLight',
  '$sortAltLight',
  '$fileLight',
  '$listAltLight',
  '$route',
  '$printLight',
  '$calendarLight',
];

export const LOCALES_LIST = [
  {
    name: 'Русский',
    short: 'ru',
    flag: 'ru_flag',
  },
  {
    name: 'English',
    short: 'en',
    flag: 'en_flag',
  },
  {
    name: 'Francais',
    short: 'fr',
    flag: 'fr_flag',
  },
  {
    name: 'Polski',
    short: 'pl',
    flag: 'pl_flag',
  },
  {
    name: 'Latviesu',
    short: 'lv',
    flag: 'lv_flag',
  },
  {
    name: 'Türk',
    short: 'tr',
    flag: 'tr_flag',
  },
  {
    name: 'Қазақ',
    short: 'kz',
    flag: 'kz_flag',
  },
];

export const SENTIMENTS = {
  negative: 'negative',
  positive: 'positive',
  neutral: 'neutral',
  undefined: 'undefined',
};

export const SENTIMENT_COLORS = {
  negative: 'tt-light-red',
  positive: 'tt-light-green vibrant',
  neutral: 'tt-light-yellow vibrant',
  undefined: 'tt-light-mono-16',
};

export const GRAY_COLORS = [
  '#e6eef4',
  '#e3eaf0',
  '#dde5ea',
  '#D7DEE1',
  '#A0B0B8',
];

export const SENTIMENT_SORT_DIRECTIONS = {
  negative_desc: 'negative_desc',
  negative_asc: 'negative_asc',
  positive_desc: 'positive_desc',
  positive_asc: 'positive_asc',
  neutral_desc: 'neutral_desc',
  neutral_asc: 'neutral_asc',
  desc: 'desc',
  asc: 'asc',
};

export const SORT_DIRECTIONS_NO_SENTIMENT = {
  desc: 'desc',
  asc: 'asc',
};

export const SORT_DIRECTIONS = {
  no_sort: 'no_sort',
  asc: 'asc',
  desc: 'desc',
};

export const AGREEMENT_COLORS = {
  disagree: 'tt-light-red',
  not_agree: 'tt-light-red',
  rather_disagree: 'tt-light-yellow vibrant',
  rather_agree: 'light-green accent-3',
  notAgree: 'tt-light-red',
  ratherDisagree: 'tt-light-yellow vibrant',
  ratherAgree: 'light-green accent-3',
  agree: 'tt-light-green',
  critic: 'tt-light-red',
  neutral: 'tt-light-yellow vibrant',
  supporter: 'tt-light-green',
};
export const AGREEMENT_COLORS_INVERTED = {
  disagree: 'tt-light-green',
  agree: 'tt-light-red',
  rather_agree: 'tt-light-yellow vibrant',
  rather_disagree: 'light-green accent-3',
  not_agree: 'tt-light-green',
  ratherAgree: 'tt-light-yellow vibrant',
  ratherDisagree: 'light-green accent-3',
  notAgree: 'tt-light-green',
  supporter: 'tt-light-red',
  neutral: 'tt-light-yellow vibrant',
  critic: 'tt-light-green',
};

export const LICENCES_LIST = {
  ENGAGEMENT_FULL: 'engagement_full',
  CLUSTERIZATION: 'clusterization',
  TSXASS: 'tsxass',
  FLEXIBLE_CONSTRUCTOR: 'flexible_constructor',
  ENABLE_PDF_DOWNLOADING: 'enable_pdf_downloading',
  IS_WORTHY_TO_SEE_METHODOLOGY: 'is_worthy_to_see_metodology',
  FOLLOW_THE_WHITE_RABBIT: 'follow_the_white_rabbit',
  SMS_IN_ENGAGEMENT: 'sms_in_engagement',
  ANONYMITY_SETTING: 'anonymity_setting',
  EXCEL_ENGAG_INDEX: 'excel_engag_index',
  SHOW_PRIORITY_TAB: 'show_priority_tab',
  SURVEY_RELEASENOTES: 'surveys_releasenotes',
  GPT_SURVEYS: 'gpt_surveys',
  GPT_SEVER: 'gpt_sever',
  HIDE_RELEASENOTES: 'hide_releasenotes',
  HIDE_GPT: 'hide_gpt',
  GPT_TOP_15: 'GPT_top15',
  HIDE_PERCENTAGE_GPT: 'hide_percentage_gpt',
  ENGAGEMENT_ANALYTICS_POSTGRES: 'engagement_analytics_postgres',
  SHOW_ACTION_PLAN_SETTINGS: 'show_action_plan_settings',
  // TODO: костыль для прямых ссылок
  SEVERSTAL_XLS: 'severstal_xls',
  SVEZA_XLS: 'sveza_xls',
  SILOVIE_XLS: 'silovie_xls',
  LENTA_XLS: 'lenta_xls',
  AGROUP_XLS: 'agroup_xls',
  SCANDI_XLS: 'scandi_xls',
  FS_XLS: 'fs_xls',
  //
  ATON_360: 'aton_360',
};

export const INDEX_STRUCTURE_COLORS_LIST = ['#E5453F', '#F06A34', '#F8832B', '#FF8D00', '#FFA900', '#FFBF00', '#B2B400', '#929801', '#6A8D24', '#327E31'];

export const INDEXES_COMPARISON_KIND = {
  children: 'children',
  selected: 'selected',
};

export const PASTEL_COLORS = [
  '#3D5AFE',
  '#26A69A',
  '#F06292',
  '#5C6BC0',
  '#AA00FF',
  '#FFA726',
  '#00E5FF',
  '#AEEA00',
  '#AD1457',
  '#00695C',
  '#F4511E',
  '#42A5F5',
  '#512DA8',
  '#E91E63',
  '#BA68C8',
  '#26C6DA',
  '#F57C00',
  '#1DE9B6',
  '#F48FB1',
  '#607D8B',
];

export const PDF_METHODOLOGY_TYPES = {
  tt_engagement_default: ['tt_engagement_1', 'tt_engagement_2', 'tt_engagement_3'],
  svs_engagement_default: ['svs_engagement_1', 'svs_engagement_2', 'svs_engagement_3'],
  satisfaction_default: ['satisfaction_1', 'satisfaction_2'],
  enps_company_default: ['enps_company_1', 'enps_company_2', 'enps_company_3', 'enps_company_4'],
  nps_default: ['nps_1', 'nps_2', 'nps_3', 'nps_4'],
  tt_mnps_default: ['tt_mnps_1', 'tt_mnps_2', 'tt_mnps_3', 'tt_mnps_4'],
  tt_happiness_default: ['tt_happiness_1', 'tt_happiness_2', 'tt_happiness_3'],
  svs_happiness_default: ['svs_happiness_1'],
  engagement_leadership_default: ['engagement_leadership_1', 'engagement_leadership_2', 'engagement_leadership_3'],
  wellbeing_default: ['wellbeing_1'],
  vertical_factors_default: ['vertical_factors_1', 'vertical_factors_2', 'vertical_factors_3'],
  negative_events_default: ['negative_events_1'],
  safety_culture_default: ['safety_culture_1'],
  priorities_default: ['priorities_1'],
};

export const FEATURE_FLAGS = {
  USE_HELPDESKEDDY: 'use_helpdeskeddy',
  DISABLE_ONBOARDING: 'disable_onboarding',
  CHECK_COMPANY_IN_URL: 'check_company_in_url',
  TSXASS_TEMPLATE_ON_COMMON: 'tsxass_template_on_common',
  SHOW_COMMENT_TO_ANSWER: 'show_comment_to_answer',
  SHOW_RANDOMIZE_ANSWERS: 'show_randomize_answers',
  NEW_LEFT_MENU: 'new_left_menu',
};

export const REPORT_TYPE = {
  PDF: 'pdf',
  EXCEL: 'excel',
  PPTX: 'pptx',
};