import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import DayOfYear from 'dayjs/plugin/dayOfYear';
import isToday from 'dayjs/plugin/isToday';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(DayOfYear);
dayjs.extend(isToday);
dayjs.extend(utc);
dayjs.extend(timezone);

const { VUE_APP_I18N_LOCALE: primary, VUE_APP_I18N_FALLBACK_LOCALE: fallback = 'ru' } = process.env;
const locale = primary || fallback;
dayjs.locale(locale);

const install = (vue) => {
  Object.defineProperty(vue.prototype, '$dayjs', {
    get() {
      return dayjs;
    },
  });
};

Vue.use(install);

export default dayjs;
