import { userInfoGet } from '@/services/api/engagement';

const USER_INFO_RETRY_DELAY = 5000;
const BODY_RETRY_CLASSNAME = 'waiting-for-user-info';

export default async function userInfoGetIterable() {
  let user: ReturnType<typeof userInfoGet>;
  try {
    user = await userInfoGet();
  } catch (err) {
    user = null;
  }
  if (user && !user.id) {
    document.body.classList.add(BODY_RETRY_CLASSNAME);
    await new Promise((resolve) => setTimeout(resolve, USER_INFO_RETRY_DELAY));
    user = await userInfoGetIterable();
  }
  document.body.classList.remove(BODY_RETRY_CLASSNAME);
  return user;
}
