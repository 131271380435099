import { Store } from 'vuex';
import {
  toggleSnack,
  toggleLoading,
  toggleNavDrawer,
  toggleReleaseNotes,
  SET_COMPANIES,
  SET_USER,
  SET_ACTIVE_COMPANY_ID,
  SET_ABILITIES,
  SET_USER_ID,
  SET_USER_ROLES,
  SET_LICENSES,
  SET_FEATURE_FLAGS,
} from '@/plugins/vuex/mutationTypes';
import modules from '@/plugins/vuex/modules';
import plugins from '@/plugins/vuex/plugins';
import { ROLES } from '@/helpers/constants';

const strict = process.env.NODE_ENV === 'development';

function createStore() {
  const store = new Store({
    strict,
    plugins,
    modules,

    // TODO: refactor use modules
    state: {
      loading: false,
      navDrawer: true,
      releaseNotesHasBeenShown: false,
      snackbar: {
        value: false,
        message: '',
        color: '',
        timeout: 3000,
      },
      user: {},
      abilities: [],
      companies: [],
      roles: [],
      activeCompanyId: '',
      activeUserId: '',
      licenses: [],
      featureFlags: [],
    },

    actions: {},

    mutations: {
      [toggleLoading](state, payload) {
        state.loading = payload;
      },

      [toggleNavDrawer](state, payload) {
        state.navDrawer = payload;
      },

      [toggleReleaseNotes](state, payload) {
        state.releaseNotesHasBeenShown = payload;
      },

      [toggleSnack](state, payload) {
        const { snackbar } = state;
        snackbar.value = !snackbar.value;
        snackbar.message = payload.message || '';
        snackbar.color = payload.color || '';
      },

      [SET_USER](state, payload) {
        state.user = payload;
      },

      [SET_COMPANIES](state, { companies }) {
        state.companies = companies;
      },

      [SET_ACTIVE_COMPANY_ID](state, payload) {
        state.activeCompanyId = payload;
      },
      [SET_ABILITIES](state, { abilities }) {
        state.abilities = abilities;
      },
      [SET_USER_ID](state, userId) {
        state.activeUserId = userId;
      },
      [SET_USER_ROLES](state, { roles }) {
        state.roles = roles;
      },
      [SET_LICENSES](state, { licenses }) {
        state.licenses = licenses;
      },
      [SET_FEATURE_FLAGS](state, { featureFlags }) {
        state.featureFlags = featureFlags;
      },
    },

    getters: {
      activeCompanyName: (state) => {
        const found = state.companies.find((company) => company.id === state.activeCompanyId);
        return found?.name;
      },
      can: (state) => (ability) => state.abilities.includes(ability),
      hasFlag: (state) => (flag) => state.featureFlags.includes(flag),
      isManager: (state) => state.roles.includes(ROLES.MANAGER),
    },
  });

  return store;
}

export default createStore;
