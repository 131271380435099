<template>
  <VChipGroup
    :data-test="dataTest"
    :data-test-label="dataTestLabel"
    :data-test-value="dataTestValue"

    v-bind="{...$attrs, ...$props}"
    v-on="$listeners"
  >
    <slot />
  </VChipGroup>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'TTChipGroup',
  model: {
    event: 'change',
  },
  props: {
    dataTest: {
      type: String,
      default: 'tt-chip-group',
    },
    dataTestLabel: {
      type: String,
      default: '',
    },
    dataTestValue: {
      type: String,
      default: '',
    },
  },
});
</script>
