import { R_ERROR_403 } from '@/plugins/vue-router/routeNames';

/*

  Формат переменной redirections:
  {
    can_create_survey: { name: Names.R_APP_USER_SURVEYS },
    default: { name: Names.R_APP_SURVEYS }
  }
*/

function createRedirectionByAbilityMiddleware(redirections) {
  function middleware(store) {
    // устанавливаем дефолтный роут, если не найдём подходящего
    let route = redirections.default || { name: R_ERROR_403 };
    // ищем роут по совпадению его абилки среди списка абилок текущего пользователя
    const abilityFound = store.state.abilities.find((ability) => redirections[ability]);
    if (abilityFound) {
      route = redirections[abilityFound];
    }
    return route;
  }
  return middleware;
}

export default createRedirectionByAbilityMiddleware;
