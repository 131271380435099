import { render, staticRenderFns } from "./App.vue?vue&type=template&id=2255bd68"
import script from "./App.vue?vue&type=script&lang=js"
export * from "./App.vue?vue&type=script&lang=js"
import style0 from "@fortawesome/fontawesome-pro/css/light.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "@fortawesome/fontawesome-pro/css/regular.css?vue&type=style&index=1&prod&lang=css&external"
import style2 from "@fortawesome/fontawesome-pro/css/solid.css?vue&type=style&index=2&prod&lang=css&external"
import style3 from "@fortawesome/fontawesome-pro/css/fontawesome.css?vue&type=style&index=3&prod&lang=css&external"
import style4 from "@/styles/main.scss?vue&type=style&index=4&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports