import Shepherd from 'shepherd.js';
import i18n from '@/plugins/vue-i18n';
import transportInterface from '@/utils/cookiesTransportInterface';

const LS_NAME = 'sgtt_onboarding';
const cookie = transportInterface(LS_NAME);
const cookieValue = cookie.get();

if (cookieValue) {
  localStorage.setItem(LS_NAME, cookieValue);
  cookie.remove();
}

function addShepherdClassnameToBody() {
  document.body.classList.add('shepherd-container');
}

function removeShepherdClassnameFromBody() {
  document.body.classList.remove('shepherd-container');
}

export function isTourPassed(tourName) {
  const tourNamesPassed = localStorage.getItem(LS_NAME);
  return tourNamesPassed?.split(',').includes(tourName);
}

export function setTourPassed(tourName) {
  const tourNamesPassed = localStorage.getItem(LS_NAME);
  if (tourNamesPassed) {
    const newTourNamesPassed = tourNamesPassed.split(',');
    newTourNamesPassed.push(tourName);
    localStorage.setItem(LS_NAME, newTourNamesPassed.join(','));
  } else {
    localStorage.setItem(LS_NAME, tourName);
  }
}

export function emptyPassedTours() {
  localStorage.setItem(LS_NAME, '');
}

export function createShepherdSteps(steps, tourName, shepherdTour) {
  return steps.map((step, idx) => {
    const buttons = [];
    if (idx > 0) {
      buttons.push({
        text: i18n.t('onboarding.back'),
        action: shepherdTour.back,
      });
    }
    if (idx < steps.length - 1) {
      buttons.push({
        text: i18n.t('onboarding.next'),
        action: shepherdTour.next,
      });
    }
    if (idx === steps.length - 1) {
      buttons.push({
        text: i18n.t('onboarding.completeTour'),
        action: () => {
          setTourPassed(tourName);
          shepherdTour.next();
        },
      });
    }
    if (!step) {
      debugger;
    }
    return {
      ...step,
      modalOverlayOpeningPadding: 5,
      modalOverlayOpeningRadius: 4,
      attachTo: {
        on: step.on || 'left',
        element: step.selector,
      },
      buttons,
    };
  });
}

export function addStepsToShepherdTour(steps, tourName, shepherdTour) {
  const shepherdSteps = createShepherdSteps(steps, tourName, shepherdTour);
  shepherdTour.addSteps(shepherdSteps);
}

export function createShepherdTour(tour, tourName) {
  const shepherdTour = new Shepherd.Tour({ ...tour.options, tourName });
  function filterSteps() {
    const steps = tour.steps.filter(step => Boolean(document.querySelector(step.selector)));
    addStepsToShepherdTour(steps, tourName, shepherdTour);
  }
  shepherdTour.on('start', addShepherdClassnameToBody);
  shepherdTour.on('start', filterSteps);
  shepherdTour.on('complete', removeShepherdClassnameFromBody);
  return shepherdTour;
}

export function addStepsToActiveShepherdTour(tour, tourName) {
  let activeTour;
  if (Shepherd.activeTour) {
    const steps = tour.steps.filter(step => Boolean(document.querySelector(step.selector)));
    addStepsToShepherdTour(steps, tourName, Shepherd.activeTour);
  } else {
    activeTour = createShepherdTour(tour, tourName).start();
  }
  return Shepherd.activeTour || activeTour;
}

export function stopActiveShepherdTour() {
  if (Shepherd.activeTour) {
    Shepherd.activeTour.complete();
  }
}
