/*
 * Обязательный interceptor запросов, для установки Content-Type заголовка
 */
async function formData(config) {
  if (config.data instanceof FormData) {
    const headers = { 'Content-Type': 'multipart/form-data' };

    Object.assign(config.headers, headers);
  }

  return config;
}

export default formData;
