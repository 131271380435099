import { companyId } from '@/services/cookies';
import store from '@/plugins/vuex';
import { FEATURE_FLAGS } from '@/helpers/constants';
import { getDomainForCookies } from '@/helpers/url';
import * as Names from '@/plugins/vue-router/routeNames';

const { VUE_APP_GATEKEEPER_ADDRESS } = process.env;

function redirectToGatekeeper() {
  companyId.remove({ domain: getDomainForCookies() });

  // IMPORTANT: редиректим в гейткипер с сылкой на текущую страницу
  const url = new URL(`/?back=${window.location.href}`, VUE_APP_GATEKEEPER_ADDRESS);
  window.location.replace(url.href);
}

/*
 * Обязательный guard для выполнения перед всеми переходами
 */
// eslint-disable-next-line
function hasCompany(to, from, next) {
  if (to.name === Names.R_CHIEF_REGISTRATION_SIGN_UP) {
    return next();
  }
  if (to.name === Names.R_APP_DEMO_USER_REGISTRATION) {
    return next();
  }

  const id = companyId.get();

  if (!id) {
    redirectToGatekeeper();
    return next(false);
  }
  return next();
}

/*
 * Обязательный guard для выполнения перед всеми переходами
 */
// eslint-disable-next-line
function checkCompanyInUrl(to, from, router) {
  if (to.name === Names.R_CHIEF_REGISTRATION_SIGN_UP) {
    return;
  }
  if (to.name === Names.R_APP_DEMO_USER_REGISTRATION) {
    return;
  }
  const id = companyId.get();
  const nextTo = {
    ...to,
    query: {
      ...to.query,
      x_company_id: id,
    },
  };
  if (!to.query.x_company_id && id) {
    setTimeout(() => {
      router.replace(nextTo);
    }, 100);
  } else if (to.query.x_company_id !== id && store.getters.hasFlag(FEATURE_FLAGS.CHECK_COMPANY_IN_URL)) {
    redirectToGatekeeper();
  }
}

export { hasCompany, checkCompanyInUrl };
