const middlewarePipeline = ({ to, next, store }) => {
  const middlewares = new Set(to.matched.flatMap((r) => (r.meta.middleware ? r.meta.middleware : [])));
  if (middlewares.size === 0) {
    return next();
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const middleware of middlewares) {
    const result = middleware(store);
    if (result) {
      return next(result);
    }
  }
  return next();
};

export default middlewarePipeline;
