import accessToken from '@/services/cookies/accessToken';
import companyId from '@/services/cookies/companyId';
import { getDomainForCookies } from '@/helpers/url';

const { VUE_APP_ARCHIVIST_ADDRESS = 'http://localhost:8083/', VUE_APP_GATEKEEPER_ADDRESS = 'http://localhost:8082/' } =
  process.env;

/*
 * Обязательный interceptor для обработки глобальных и локальный ошибок
 *
 * @see: https://burning-heart.atlassian.net/wiki/spaces/FND/pages/1983938561
 */
async function onError(error) {
  const HTTP_FORBIDDEN = 403;
  const currentModuleUrl = new URL(window.location.href);
  const archivistModuleUrl = new URL(VUE_APP_ARCHIVIST_ADDRESS);
  const gatekeeperModuleUrl = new URL(VUE_APP_GATEKEEPER_ADDRESS);
  const flushAuth = () => {
    accessToken.remove({ domain: getDomainForCookies() });
    companyId.remove({ domain: getDomainForCookies() });
  };
  // HACK: после того как fatal и нормальный json ответ приедет во все сервисы убрать fallback
  const fallback = error.response?.data === null;
  const isGlobalError =
    error.isAxiosError === true &&
    [HTTP_FORBIDDEN].includes(error.response?.status) &&
    (fallback || Boolean(error.response?.data?.error?.fatal) === true);
  const isArchivistModule = currentModuleUrl.origin === archivistModuleUrl.origin;
  const hasRetry = Boolean(currentModuleUrl.searchParams.get('retry')) === true;
  // TODO: whitelist для урлов и back урлов
  const redirectTo = targetUrl => {
    if (window.redirectTriggered) {
      return;
    }
    window.redirectTriggered = true;
    window.location.replace(targetUrl);
  };

  if (isGlobalError) {
    // стандартные ошибки игнорируем, если пользователь на служебной странице регистрации демо-пользователя
    const isDemoUrl = window.location.href.indexOf('/demo/') > -1;
    if (isDemoUrl) {
      return Promise.reject(error);
    }
    if (isArchivistModule) {
      // Глобальная ошибка в профиле
      flushAuth();

      redirectTo(gatekeeperModuleUrl.href);
    } else if (hasRetry) {
      // Глобальная ошибка в модуле и есть флаг retry
      redirectTo(archivistModuleUrl.href);
    } else {
      if (currentModuleUrl.pathname === '/chief_registration/sign_up') {
        return;
      }
      // Глобальная ошибка в модуле и нет флага retry
      flushAuth();
      currentModuleUrl.searchParams.set('retry', 'true');

      const url = new URL(gatekeeperModuleUrl);
      url.searchParams.set('back', currentModuleUrl.href);

      redirectTo(url.href);
    }
  }

  // eslint-disable-next-line consistent-return
  return Promise.reject(error);
}

export { onError };
