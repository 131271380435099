<template>
  <VSwitch
    inset
    :ripple="false"
    :data-test="dataTest"
    :data-test-label="dataTestLabel"
    :data-test-value="dataTestValue"
    class="tt-switch"
    v-bind="{...$props, ...$attrs}"
    v-on="$listeners"
  >
    <template v-slot:label>
      <slot name="label" />
    </template>
  </VSwitch>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'TTSwitch',
  model: {
    event: 'change',
  },
  props: {
    dataTest: {
      type: String,
      default: 'tt-switch',
    },
    dataTestLabel: {
      type: String,
      default: '',
    },
    dataTestValue: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'tt-primary',
    },
    dense: Boolean,
    loading: Boolean,
    disabled: Boolean,
    readonly: Boolean,
  },
});
</script>

<style lang="scss" src="./TTSwitch.scss"/>
