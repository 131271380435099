import * as Names from '@/plugins/vue-router/routeNames';

export default {
  name: 'RouteNamesProvider',

  provide: {
    // Note: удаляет Symbol.ESModule, vue-devtools тогда не думает что Names это ES модуль.
    Names: { ...Names },
  },

  render() {
    return this.$slots.default;
  },
};
