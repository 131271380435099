import transport from '@/services/api/feedback/transport';
import * as methods from '@/services/api/constants';

/**
 * Гайд по именованию ресурсов
 * https://burning-heart.atlassian.net/wiki/spaces/front/pages/1375109156/JavaScript
 *
 */

export const usersSurveysGet = (data = {}, ...options) => transport({
  url: '/v1/surveys',
  method: methods.HTTP_GET,
  data,
  ...options,
});

export const adminSurveysGet = (options) => transport({
  url: '/v1/admin/surveys',
  method: methods.HTTP_GET,
  ...options,
});

export const adminSurveysPost = (data = {}, ...options) => transport({
  url: '/v1/admin/surveys',
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const adminSurveysDelete = (surveyId, ...options) => transport({
  url: `/v1/admin/surveys/${surveyId}`,
  method: methods.HTTP_DELETE,
  ...options,
});

export const adminSurveyAvailableOfAnalyticsPost = (surveyId, data = {}, ...options) => transport({
  url: `/v1/admin/surveys/${surveyId}/available_of_analytics`,
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const surveysKanban360Get = (data = {}, ...options) => transport({
  url: '/v1/surveys/kanban',
  method: methods.HTTP_GET,
  data,
  ...options,
});

export const surveysKanban360Delete = (slug, data = {}, ...options) => transport({
  url: `/v1/admin/surveys/${slug}`,
  method: methods.HTTP_DELETE,
  data,
  ...options,
});

export const surveysKanban360Finish = (slug, data = {}, ...options) => transport({
  url: `/v1/admin/surveys/${slug}/finished`,
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const surveysKanban360Start = (slug, data = {}, ...options) => transport({
  url: `/v1/admin/surveys/${slug}/started`,
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const surveysKanban360ToDraft = (slug, data = {}, ...options) => transport({
  url: `/v1/admin/surveys/${slug}/stop`,
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const adminSurveyGet = (slug, data = {}, ...options) => transport({
  url: `/v1/admin/surveys/${slug}`,
  method: methods.HTTP_GET,
  data,
  ...options,
});

export const adminSurveySettingsPost = (slug, data = {}, ...options) => transport({
  url: `/v1/admin/surveys/${slug}/settings`,
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const userInfo360Get = (data = {}, ...options) => transport({
  url: '/v1/me',
  method: methods.HTTP_GET,
  data,
  ...options,
});
