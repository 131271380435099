import Router from 'vue-router';

function createRouter({ routes, baseUrl, ...rest }) {
  return new Router({
    mode: 'history',
    baseUrl,
    // Note: showing in Routing tab: devtools@^5.0.1
    routes,
    ...rest,
  });
}

export default createRouter;
