import { R_APP } from '@/plugins/vue-router/routeNames';

function createAbilityMiddleware(abilitiesArr) {
  function middleware(store) {
    if (!abilitiesArr.some((abilityName) => store.state.abilities.includes(abilityName))) {
      return { name: R_APP };
    }
    return false;
  }
  return middleware;
}

export default createAbilityMiddleware;
