import * as steps from './steps';

const defaultTourOptions = {
  useModalOverlay: true,
  keyboardNavigation: false,
  defaultStepOptions: {
    scrollTo: { behavior: 'smooth', block: 'center' },
    canClickTarget: false,
    cancelIcon: {
      enabled: false,
    },
    popperOptions: {
      modifiers: [{ name: 'offset', options: { offset: [0, 12] } }],
    },
  },
};

export default {
  appAsideLeft: {
    options: defaultTourOptions,
    steps: [
      steps.userSurveysBtnAppAsideLeft,
      steps.surveysBtnAppAsideLeft,
      steps.constructorBtnAppAsideLeft,
      steps.hrPracticesBtnAppAsideLeft,
      steps.linkTo360BtnAppAsideLeft,
      steps.complexReportsBtnAppAsideLeft,
      steps.settingsBtnAppAsideLeft,
    ],
  },
  constructionCommon: {
    options: defaultTourOptions,
    steps: [steps.runConstructorBtn, steps.constructorTemplateCard],
  },
  constructionSettings: {
    options: defaultTourOptions,
    steps: [steps.constructorSettingsBtn],
  },
  constructionBlockEdit: {
    options: defaultTourOptions,
    steps: [steps.constructorPreviewBlock],
  },
  constructionQuestionBlockEdit: {
    options: defaultTourOptions,
    steps: [steps.constructorQuestionTypeSelect],
  },
  constructorQuestionTypeTabs: {
    options: defaultTourOptions,
    steps: [
      steps.constructorBaseQuestionsTab,
      steps.constructorSearchBaseQuestionInput,
      steps.constructorUserQuestionsTab,
    ],
  },
  constructorTabsAsideRight: {
    options: defaultTourOptions,
    steps: [steps.constructorActionTabAside],
  },
  constructorTabAction: {
    options: defaultTourOptions,
    steps: [steps.constructorActionTabBranchingBtn, steps.constructorActionTabTestingBtn],
  },
  constructorTabStructure: {
    options: defaultTourOptions,
    steps: [
      steps.constructorStructureTitle,
      steps.constructorStructureFinalPage,
      steps.constructorStructureNewPage,
      steps.constructorAddBlockBtn,
    ],
  },
  constructorSurveyLink: {
    options: defaultTourOptions,
    steps: [steps.constructorAnonymousLink],
  },
  constructorOrgstructure: {
    options: defaultTourOptions,
    steps: [steps.constructorOrgstructureCheckbox],
  },
  constructorDeliveryChannels: {
    options: defaultTourOptions,
    steps: [steps.constructorDeliveryChannelsTabs, steps.constructorTestEmailSendBtn],
  },
  helpdesk: {
    options: {
      ...defaultTourOptions,
      defaultStepOptions: {
        ...defaultTourOptions.defaultStepOptions,
        classes: 'shepherd-helpdesk',
        canClickTarget: true,
        popperOptions: {
          modifiers: [{ name: 'offset', options: { offset: [60, 0] } }],
        },
      },
    },
    steps: [steps.helpdeskBtn],
  },
};
