import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/plugins/vuex';
import createRouter from '@/plugins/vue-router/router';
import routes from '@/plugins/vue-router/routes';
import { hasToken } from '@/plugins/vue-router/hasToken';
import { hasCompany, checkCompanyInUrl } from '@/plugins/vue-router/hasCompany';
import yandexMetrica from '@/plugins/vue-router/yandexMetrica';
import middlewarePipeline from '@/plugins/vue-router/guards/middlewarePipeline';

Vue.use(VueRouter);

const { BASE_URL } = process.env;

const router = createRouter({
  baseUrl: BASE_URL,
  routes,
});

router.beforeEach(hasToken);
router.beforeEach(hasCompany);
router.beforeEach((to, from, next) => middlewarePipeline({ to, next, store }));

router.afterEach(yandexMetrica);
router.afterEach((to, from) => { checkCompanyInUrl(to, from, router); });

export * from '@/plugins/vue-router/routeNames';
export default router;
