const aclModule = {
  namespaced: true,

  state() {
    return {
      user: {},
      role: 'guest',
      ACL: ['guest', 'manager', 'admin'],
    };
  },
  actions: {},

  mutations: {},

  getters: {
    can: (state) => (required) => state.ACL.indexOf(state.role) >= state.ACL.indexOf(required),
  },
};

export default aclModule;
