import Vue from 'vue';
import {
  i18n, router, store, vuetify, dayjs,
} from '@/plugins';

import '@/plugins/sentry';

import TTAppBar from '@uikit/components/TTAppBar.vue';
import TTBtn from '@uikit/components/TTBtn/TTBtn.vue';
import TTDataTable from '@uikit/components/TTDataTable/TTDataTable.vue';
import TTNavigationDrawer from '@uikit/components/TTNavigationDrawer.vue';
import TTToolBarTitle from '@uikit/components/TTToolbarTitle.vue';
import TTSelect from '@uikit/components/TTTSelect/TTSelect.vue';
import TTTextField from '@uikit/components/TTTextField/TTTextField.vue';
import TTTooltip from '@uikit/components/TTTooltip/TTTooltip.vue';
// @ts-ignore
import TTSwitch from '@uikit/components/TTSwitch/TTSwitch.vue';
// @ts-ignore
import TTAlert from '@uikit/components/TTAlert/TTAlert';
// @ts-ignore
import TTCheckbox from '@uikit/components/TTCheckbox/TTCheckbox';
// @ts-ignore
import TTRadio from '@uikit/components/TTRadio/TTRadio';
import TTRadioGroup from '@uikit/components/TTRadioGroup/TTRadioGroup.vue';
import TTTextarea from '@uikit/components/TTTextarea/TTTextarea.vue';
import TTModuleMenu from '@uikit/components/TTModuleMenu/TTModuleMenu.vue';
import TTModuleMenuV2 from '@uikit/components/TTModuleMenuV2/TTModuleMenuV2.vue';
import TTChip from '@uikit/components/TTChip/TTChip.vue';
import TTChipGroup from '@uikit/components/TTChipGroup/TTChipGroup.vue';
// @ts-ignore
import TTNotify from '@uikit/components/TTNotify/TTNotify';
import TTAutocomplete from '@uikit/components/TTAutocomplete/TTAutocomplete.vue';
import TTAvatar from '@uikit/components/TTAvatar/TTAvatar.vue';
import App from '@/App.vue';
import {
  setAbilities, setActiveUser, setUserRoles, setLicenses, setFeatureFlags,
} from '@/helpers/store';
import { appsGet } from '@/services/api/profile';
import { userInfo360Get } from '@/services/api/feedback';
import { featureFlagsGet } from '@/services/api/feature-flags';
import { LICENCES_LIST } from '@/helpers/constants';
import userInfoGetIterable from './userInfoGetIterable';
import isDemoUser from '@/services/cookies/isDemoUser';

import 'quill/dist/quill.core.css'; // import styles
import 'quill/dist/quill.snow.css'; // for snow theme
import 'quill/dist/quill.bubble.css';

Vue.component(TTAppBar.name, TTAppBar);
Vue.component(TTBtn.name, TTBtn);
Vue.component(TTDataTable.name, TTDataTable);
Vue.component(TTNavigationDrawer.name, TTNavigationDrawer);
Vue.component(TTToolBarTitle.name, TTToolBarTitle);
Vue.component(TTSelect.name, TTSelect);
Vue.component(TTTextField.name, TTTextField);
Vue.component('TTCheckbox', TTCheckbox);
Vue.component('TTRadio', TTRadio);
Vue.component('TTRadioGroup', TTRadioGroup);
Vue.component(TTTextarea.name, TTTextarea);
Vue.component(TTModuleMenu.name, TTModuleMenu);
Vue.component(TTModuleMenuV2.name, TTModuleMenuV2);
Vue.component('TTAlert', TTAlert);
Vue.component('TTChip', TTChip);
Vue.component('TTChipGroup', TTChipGroup);
Vue.component('TTNotify', TTNotify);
Vue.component('TTAutocomplete', TTAutocomplete);
Vue.component('TTAvatar', TTAvatar);
Vue.component(TTTooltip.name, TTTooltip);
Vue.component('TTSwitch', TTSwitch);

Vue.config.productionTip = false;

(async () => {
  let featureFlags;
  // фичафлаги могут не работать, тогда мокаем ответ
  if (process.env.VUE_APP_T8H_ENABLE?.toLowerCase() === 'true') {
    featureFlags = await featureFlagsGet();
  } else {
    featureFlags = {
      features: [
        {
          name: 'use_helpdeskeddy',
          enabled: true,
        },
        {
          name: 'check_company_in_url',
          enabled: true,
        },
      ],
    };
  }
  try {

    if (!isDemoUser.get()) {
      let user = await userInfoGetIterable();
      const licenses = await appsGet();
      setLicenses(store.commit, licenses);
      // @ts-ignore
      if (licenses.some((license) => license.appName === LICENCES_LIST.TSXASS)) {
        const user360 = await userInfo360Get();
        user = {
          ...user,
          abilities: [...user360.abilities, ...user.abilities],
        };
      }
      const { abilities, userExternalId, roles } = user;
      setAbilities(store.commit, abilities);
      setActiveUser(store.commit, userExternalId);
      setUserRoles(store.commit, roles);
    }
    setFeatureFlags(
      store.commit,
      // @ts-ignore
      featureFlags.features.filter((ff) => ff.enabled).map((ff) => ff.name),
    );
  } catch (err) {
    // no-error
  } finally {
    new Vue({
      name: 'TalentTech',
      i18n,
      router,
      store,
      // @ts-ignore
      dayjs,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app');
  }
})();
