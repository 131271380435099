import Vue from 'vue';
import { FEATURE_FLAGS } from '@/helpers/constants';
import { detectIncognito } from 'detectincognitojs';
import tours from './tours';
import { addStepsToActiveShepherdTour, isTourPassed } from './utils';
import 'shepherd.js/dist/css/shepherd.css';

let isIncognitoMode = false;

detectIncognito().then(result => {
  isIncognitoMode = result.isPrivate;
});

const install = vue => {
  // eslint-disable-next-line
  vue.prototype.$onboarding = function (tourName) {
    if (this.$store.state.featureFlags.includes(FEATURE_FLAGS.DISABLE_ONBOARDING) || isIncognitoMode) {
      return;
    }
    const tourFound = tours[tourName];
    if (tourFound && !isTourPassed(tourName)) {
      const shepherdTour = addStepsToActiveShepherdTour(tourFound, tourName);
      if (!shepherdTour) return;
      shepherdTour.on('complete', () => {
        // после завершения активного тура надо принудительно запустить тур helpedsk
        if (!isTourPassed('helpdesk')) {
          setTimeout(() => {
            // сразу запускать нельзя
            const helpdeskTour = addStepsToActiveShepherdTour(tours.helpdesk, 'helpdesk');
            // при клике в любом месте завершаем тур helpdesk
            window.addEventListener('click', () => {
              helpdeskTour?.complete();
            });
          }, 100);
        }
      });
    }
  };
};

Vue.use(install);

export default {};
