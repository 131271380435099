import crypto from 'crypto';
import { accessToken } from '@/services/cookies';

let isInit = false;
let script;

export const usedesk = (profile, additional_fields = []) => {
  if (!isInit) {
    script = document.createElement('script');
    const accessTokenValue = accessToken.get().split('.');
    const { sub: uuid } = JSON.parse(atob(accessTokenValue[1]));

    const fio = Object.values(profile.name).join(' ');

    const currentApplicationHost = window.location.hostname.split('.').slice(1).join('.') || 'localhost';

    const token = crypto.createHash('sha256').update(`${uuid}_${currentApplicationHost}`).digest('hex');

    const identity = {
      name: fio,
      email: profile.email || '',
      phone: profile.phoneNumber || '',
      token,
      additional_fields,
    };

    // eslint-disable-next-line no-underscore-dangle
    window.__widgetInitCallback = (widget) => {
      widget.identify(identity);
    };

    script.src = process.env.VUE_APP_USEDESK_SCRIPT;
    document.body.appendChild(script);

    isInit = true;
  }
};

export const usedeskNew = (profile) => {
  if (!isInit) {
    script = document.createElement('script');

    script.src = process.env.VUE_APP_USEDESK_NEW_SCRIPT;
    script.setAttribute('data-lang', 'ru');
    script.setAttribute('id', 'hde-chat-widget');
    script.setAttribute('data-host', 'servicedesk.potok.io');
    script.setAttribute('data-position', 'bottom-right');
    script.setAttribute('defer', 'defer');
    document.body.appendChild(script);

    isInit = true;
  }

  const fio = Object.values(profile.name).join(' ');
  script.setAttribute('data-user-name', fio);
  script.setAttribute('data-user-email', profile.email || '');
};
export default { usedeskNew, usedesk };
