export const getDomainFromURL = (location) => new URL(location).hostname.split('.').slice(-2).join('.');

export function getDomainForCookies() {
  /**
   * Note: Получаем текущий домен.
   * Устанавливаем куки всегда на текущий домен и для всех сабдоменов.
   * Точку добавляем, чтобы куки распространялись на все сабдомены
   * Точку не добавляем для localhost
   * */
  let domain = `.${getDomainFromURL(window.location)}`;
  domain = domain.startsWith('.localhost') ? domain.slice(1) : domain;
  return domain;
}
