import {
  toggleSnack,
  SET_ABILITIES,
  SET_USER_ID,
  SET_USER_ROLES,
  SET_LICENSES,
  SET_FEATURE_FLAGS,
} from '@/plugins/vuex/mutationTypes';

export const snackSuccess = (commit, message) => commit(
  toggleSnack,
  {
    color: 'success',
    message,
  },
  { root: true },
);

export const snackError = (commit, message) => commit(
  toggleSnack,
  {
    color: 'error',
    message,
  },
  { root: true },
);

export const setAbilities = (commit, abilities) => commit(SET_ABILITIES, { abilities }, { root: true });

export const setActiveUser = (commit, userId) => commit(SET_USER_ID, userId, { root: true });

export const setUserRoles = (commit, roles) => commit(SET_USER_ROLES, { roles }, { root: true });

export const setLicenses = (commit, licenses) => commit(SET_LICENSES, { licenses }, { root: true });

export const setFeatureFlags = (commit, featureFlags) => commit(SET_FEATURE_FLAGS, { featureFlags }, { root: true });
