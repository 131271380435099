export const onboardingBtn = {
  selector: '#onboarding-hint-btn',
  text: `Для вас доступна функция подсказок — они помогут быстрее разобраться с сервисом.
        Их можно включить в любой момент.`,
};

export const userSurveysBtnAppAsideLeft = {
  selector: 'a[href="/app/user_surveys"]',
  text: `В разделе «Мои опросы» доступны все опросы,
        в которых вы должны принять участие.`,
};

export const surveysBtnAppAsideLeft = {
  selector: '.tt-module-menu a[href="/app/surveys"]',
  text: `Это раздел «Управление опросами».
         Здесь можно контролировать все созданные опросы,
         смотреть статистику или результаты.`,
};

export const constructorBtnAppAsideLeft = {
  selector: 'a[href="/app/construction"]',
  text: `В разделе «Создать опрос» можно воспользоваться готовыми шаблонами
        или создать любой опрос с помощью конструктора.`,
};

export const hrPracticesBtnAppAsideLeft = {
  selector: 'a[href="/app/hr_practices"]',
  text: `В «Базе знаний» вы можете найти готовые рекомендации экспертов
        для повышения метрик или поделиться с коллегами своим опытом.`,
};

export const linkTo360BtnAppAsideLeft = {
  selector: 'a[href$="/app/user_analytics"]',
  text: `В разделе «Обратная связь» можно ознакомиться с оценками коллег,
        изучить свои сильные стороны и зоны для развития.`,
};

export const complexReportsBtnAppAsideLeft = {
  selector: 'a[href="/app/complex_reports"]',
  text: `В разделе «Комплексные отчеты» вы можете объединить
        несколько одинаковых опросов, проведенных в разное время,
        в одну общую аналитику.`,
};

export const settingsBtnAppAsideLeft = {
  selector: 'a[href="/app/settings"]',
  text: `В «Настройках» вы можете создать группы пользователей
        и опции, которые им будут доступны.`,
};

export const runConstructorBtn = {
  selector: '#run-constructor-btn',
  text: `Вы можете создать опрос с помощью конструктора.
        Это гибкий инструмент, который позволяет создавать опросы любой сложности.`,
  on: 'right',
};
export const constructorTemplateCard = {
  selector: 'div[data-test-label="constructor-template-card"]',
  text: 'В библиотеке опросов доступны шаблоны опросов — это готовые опросники, созданные нашими методологами.',
  on: 'right',
};

export const constructorSettingsBtn = {
  selector: 'button[data-test-label="edit-survey-settings-btn"]',
  text: `Чтобы изменить название и описание опроса,
        а также добавить другие языки, воспользуйтесь общими настройками.`,
};
export const constructorStructureFinalPage = {
  selector: '[data-test-label="constructor-structure-final-page"]',
  text: `Страница «Спасибо» создается автоматически
        и нужна для корректного завершения опроса.
        Она всегда будет последней, её нельзя переместить или удалить.`,
};

export const constructorStructureTitle = {
  selector: '[data-test-label="constructor-structure-title"]',
  text: `Это панель управления страницей.
        Можно её переименовать, удалить, копировать или
        изменить порядок, перетащив в нужное место.`,
  on: 'left',
};

export const constructorStructureNewPage = {
  selector: '[data-test-label="constructor-structure-new-page"]',
  text: 'Добавить неограниченное количество страниц можно здесь.',
};

export const constructorAddBlockBtn = {
  selector: '[data-test-label="add-block-btn"]',
  text: `<div class="onboarding-add-block-btn"></div>
        <p>Всего доступно четыре типа блоков:
        вопрос, готовый вопрос, изображение и текст.
        Рекомендуем начинать опрос с приветственной страницы.</p>`,
};

export const constructorPreviewBlock = {
  selector: '[data-test-label="block-preview-wrapper"]',
  text: `<div class="onboarding-block-preview-wrapper"></div>
        <p>При наведении на любой блок
        его можно отредактировать, дублировать, удалить
        или перетянуть ниже или выше.</p>`,
  on: 'bottom',
};

export const constructorQuestionTypeSelect = {
  selector: '[data-test-label="question-type-select"] .v-select__slot',
  text: `<div class="onboarding-question-type-select"></div>
        <p>Для создания любого вопроса нужно выбрать его тип.
        В каком случае подходит тот или иной тип вопроса,
        мы расскажем в инструкции.</p>
        <a href="https://docs.google.com/presentation/d/1gyMLvqL89YVMIC7CeML-1tuXvA7xv-HK_9u2VH6jkW0" target="_blank">
          Смотреть инструкцию&nbsp;&nbsp;&rsaquo;
        </a>`,
  on: 'top',
};

export const constructorBaseQuestionsTab = {
  selector: '[data-test-label="baseQuestions"]',
  text: `В базе вопросов Поток вы можете найти список
        готовых формулировок вопросов с предзаданной методикой расчета аналитики.
        Их удобно использовать для отслеживания динамики.`,
  on: 'right',
};

export const constructorUserQuestionsTab = {
  selector: '[data-test-label="userQuestions"]',
  text: `Вы также можете создать базу готовых вопросов своей компании,
        чтобы в будущем облегчить создание повторных опросов и получать динамику по ним.`,
  on: 'right',
};

export const constructorSearchBaseQuestionInput = {
  selector: '[data-test-label="search-questions-input"]',
  text: `Для поиска нужного вопроса воспользуйтесь строкой поиска.
        Для сортировки всех вопросов также можно использовать теги.`,
  on: 'bottom',
};

export const constructorActionTabAside = {
  selector: '[data-test="constructor-tabs-tab"][data-test-value="actions"]',
  text: `Здесь находятся все действия, доступные для опроса,
        например, предпросмотр или настройка ветвления.
        Перейдите в раздел, чтобы ознакомиться с возможностями.`,
  on: 'bottom',
};

export const constructorActionTabBranchingBtn = {
  selector: 'a[href^="/app/construction/branching/survey/"]',
  text: `<div class="onboarding-branching-btn"></div>
        <p>Вы можете настроить ветвление опроса — развилки,
        при выборе в которых опрос будет меняться в зависимости от выбора.</p>
        <a href="https://docs.google.com/presentation/d/1ugGUsP5gax7wl1_2hpTSmJd36Zvboej64NOoxPCmX84" target="_blank">
          Смотреть инструкцию&nbsp;&nbsp;&rsaquo;
        </a>`,
  on: 'left',
};

export const constructorActionTabTestingBtn = {
  selector: '[data-test-label="show-test-link"]',
  text: `Предпросмотр опроса доступен через тестовую ссылку.
        В этом режиме можно пройти опрос, данные никуда не сохраняются.`,
  on: 'left',
};

export const constructorAnonymousLink = {
  selector: 'a[href="#anonymous"]',
  text: `<div class="onboarding-anonymous-link"></div>
        <p>В зависимости от того, как вы собираетесь распространять опрос,
        и внесены ли в систему заранее данные об участниках опроса,
        выбирайте нужный тип ссылки по описанию. Подробнее в инструкции.</p>
        <a href="https://docs.google.com/presentation/d/1g-2alSX8TtBBfJUietVosySJbMLWkDm7Rzr_ON0atNc" target="_blank">
          Смотреть инструкцию&nbsp;&nbsp;&rsaquo;
        </a>`,
  on: 'right',
};

export const constructorOrgstructureCheckbox = {
  selector: '[data-test-label="orgstructure-checkbox"] .v-input__control',
  text: `<div class="onboarding-orgstructure-checkbox"></div>
        <p>При участии в опросе по общей ссылке система ничего не знает о пользователе,
        рекомендуем включить вопрос оргструктуры,
        а также вернуться в создание вопроса и добавить готовые вопросы паспортички,
        чтобы смотреть результаты по разным срезам.</p>
        <a href="https://docs.google.com/presentation/d/1SWIHjsaEAqbJa8xHzlP1zYuTI9SO_zDeVNloj3pfyyg" target="_blank">
          Смотреть инструкцию&nbsp;&nbsp;&rsaquo;
        </a>`,
  on: 'right',
};

export const constructorDeliveryChannelsTabs = {
  selector: '[data-test-label="delivery-channel-tabs"] .channel-card:last-child',
  text: `<div class="onboarding-delivery-channel-tabs"></div>
        <p>Как выбрать правильный канал доставки опроса и в зависимости от выбранного канала
        эффективно настроить оповещение сотрудников об опросе читайте в инструкции.</p>
        <a href="https://docs.google.com/presentation/d/1tUSrm5hbJSCIT5lnmNbh23vbdZuGnYTGAyb8gWhMyIo" target="_blank">
          Смотреть инструкцию&nbsp;&nbsp;&rsaquo;
        </a>`,
  on: 'right',
};

export const constructorTestEmailSendBtn = {
  selector: '[data-test-label="test-email-send-btn"]',
  text: `<div class="onboarding-test-email-send-btn"></div>
        <p>Как проверить, что письма уходят корректно,
        не попадут в спам или не заблокируются вашей корпоративной системой читайте в инструкции.</p>
        <a href="https://docs.google.com/presentation/d/1s9v5WBPvw6Ag3scvk9qU7biQe10lTIoCF_caTE5ACxs" target="_blank">
          Смотреть инструкцию&nbsp;&nbsp;&rsaquo;
        </a>`,
  on: 'right',
};

export const helpdeskBtn = {
  selector: '#hde-container',
  text: `<h4>Удобная поддержка пользователей</h4>
          Задавайте любые вопросы в поддержку по почте <a href="mailto:help@potok.io">help@potok.io</a>
          или в чате прямо здесь, в правом нижнем углу.</p>
        <p>Получите решение быстрее! Мы проконсультируем по возможностям админки и приложения, поможем с доступами и авторизацией,
          решим технические сложности и примем вашу обратную связь по продукту Поток Опросы.</p>`,
  on: 'top-start',
};
