export const toggleSnack = 'toggleSnack';
export const toggleLoading = 'toggleLoading';
export const toggleNavDrawer = 'toggleNavDrawer';
export const toggleReleaseNotes = 'toggleReleaseNotes';

export const SET_USER = 'SET_USER';
export const SET_COMPANIES = 'SET_COMPANIES';
export const SET_ACTIVE_COMPANY_ID = 'SET_ACTIVE_COMPANY_ID';
export const SET_ABILITIES = 'SET_ABILITIES';
export const SET_USER_ID = 'SET_USER_ID';
export const SET_USER_ROLES = 'SET_USER_ROLES';
export const SET_LICENSES = 'SET_LICENSES';
export const SET_FEATURE_FLAGS = 'SET_FEATURE_FLAGS';
