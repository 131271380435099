import transport from '@/services/api/profile/transport';
import * as methods from '@/services/api/constants';

/**
 * Гайд по именованию ресурсов
 * https://burning-heart.atlassian.net/wiki/spaces/front/pages/1375109156/JavaScript
 *
 */

export const profileGet = (data = {}, options) => transport({
  url: 'profile',
  method: methods.HTTP_GET,
  data,
  ...options,
});

export const companiesGet = (data = {}, options) => transport({
  url: 'profile/companies',
  method: methods.HTTP_GET,
  data,
  ...options,
});

export const appsGet = ({ data = {}, ...options } = {}) => transport({
  url: 'profile/apps',
  method: methods.HTTP_GET,
  data,
  ...options,
});
